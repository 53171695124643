import axios from "axios";
import store from "@/store/index.js";

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = store.getters.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    config.baseURL = process.env.VUE_APP_API_URL;
    config.withCredentials = true;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      // store.commit('stopModalLoading')
      // store.commit('hideModal')
    }
    return response;
  },
  function (error) {
    // Do something with response error
    if (error.response.status === 401) {
      store.commit("AUTH_LOGOUT");
    }
    return Promise.reject(error.response);
  }
);
