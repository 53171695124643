import axios from "@/services/api";
import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
} from "@/store/actions/action";
import router from "@/router/index.js";

function initialState() {
  return {
    token: "",
    status: "",
    user: null,
  };
}

const state = {
  token: "",
  status: "",
  user: null,
};

const baseUrl = process.env.VUE_APP_API_URL;

const getters = {
  token: (state) => state.token,
  isLoggedIn: (state) => !!state.token,
  authStatus: (state) => state.status,
  user: (state) => state.user,
};

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit("AUTH_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios({
        url: `${baseUrl}/user/login`,
        data: user,
        method: "POST",
      })
        .then((resp) => {
          if (resp.status === 200) {
            const token = resp.data.data.access_token;
            const user = resp.data.data.user;
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            commit("AUTH_SUCCESS", { token, user });
          }
          resolve(resp);
        })
        .catch((err) => {
          commit("AUTH_ERROR");
          reject(err);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [AUTH_LOGOUT]: ({ commit, dispatch }) => {
    dispatch("API_PROCESSING", true, { root: true });
    return new Promise((resolve) => {
      axios
        .post("/user/logout")
        .then((resp) => {
          if (resp.status >= 200 && resp.status <= 299) {
            commit("AUTH_LOGOUT");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
      resolve();
    });
  },
};

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, params) => {
    state.status = "success";
    state.token = params.token;
    state.user = params.user;
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = "";
    router.push({ name: "Login" }).catch(() => {});

    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
    window.localStorage.clear();
    state.status = "";
    state.user = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
