import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/user",
    name: "User",
    component: () => import("../views/User.vue"),
  },
  {
    path: "/user/:id",
    name: "UserDetail",
    component: () => import("../views/UserDetail.vue"),
  },
  {
    path: "/meta",
    name: "Meta",
    component: () => import("../views/Meta.vue"),
  },
  {
    path: "/meta/:metaKey",
    name: "MetaDetail",
    component: () => import("../views/MetaDetail.vue"),
  },
  {
    path: "/faq",
    name: "Faq",
    component: () => import("../views/Faq.vue"),
  },
  {
    path: "/report/tournament",
    name: "ReportTournament",
    component: () => import("../views/ReportTournament.vue"),
  },
  {
    path: "/report/comment",
    name: "ReportComment",
    component: () => import("../views/ReportComment.vue"),
  },
  {
    path: "/report/account",
    name: "ReportUser",
    component: () => import("../views/ReportUser.vue"),
  },
  {
    path: "/report/timeline",
    name: "ReportTimeline",
    component: () => import("../views/ReportTimeline.vue"),
  },
  {
    path: "/game_list",
    name: "GameList",
    component: () => import("../views/GameList.vue"),
  },
  {
    path: "/chat",
    name: "Chat",
    component: () => import("../views/Chat.vue"),
  },
  {
    path: "/tournament",
    name: "Tournament",
    component: () => import("../views/Tournament.vue"),
  },
  {
    path: "/tournament/:id",
    name: "TournamentDetail",
    component: () => import("../views/TournamentDetail.vue"),
  },
  {
    path: "/timeline",
    name: "Timeline",
    component: () => import("../views/Timeline.vue"),
  },
  {
    path: "/catalogs",
    name: "Catalogs",
    component: () => import("../views/Catalogs.vue"),
  },
  {
    path: "/catalogs/:slug",
    name: "CatalogsDetail",
    component: () => import("../views/CatalogsDetail.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
