import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import vuetoast from "./plugins/vuetoast";
import axios from "axios";
import firebase from "firebase/app";

Vue.config.productionTip = false;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers["apiKey"] = "DDA7BF66B43EF3A19BCD5A756494B";

const firebaseConfig = {
  apiKey: "AIzaSyAm3Mg5C6h8vD3wu1mTYsXrYBVBLejpoWI",
  authDomain: "ngegame-a4693.firebaseapp.com",
  projectId: "ngegame-a4693",
  storageBucket: "ngegame-a4693.appspot.com",
  messagingSenderId: "512226396632",
  appId: "1:512226396632:web:65ac29dfd6c0e9c723caad",
  measurementId: "G-HFKZX9M6HW",
};

const firebaseapp = firebase.initializeApp(firebaseConfig);
// const app = initializeApp(firebaseConfig);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      const messaging = firebase.messaging();
      if ("undefined" !== typeof messaging.b) delete messaging.b;
      messaging.useServiceWorker(registration);
    })
    .catch((err) => {
      console.log(err);
    });
}

Vue.mixin({
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    convertToDate(dates) {
      var date = new Date(dates).toJSON();

      return date; // "Dec 20"
    },
    dateFormating(date) {
      const monthNames = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      const dayNames = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];

      let hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      const stringMinutes =
        minutes < 10 ? "0" + minutes.toString() : minutes.toString();
      const stringHours =
        hours < 10 ? "0" + hours.toString() : hours.toString();
      // return `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}, ${stringHours}:${stringMinutes} ${ampm}`

      return `${date.getDate()} ${
        monthNames[date.getMonth()]
      } ${date.getFullYear()} ${date.getHours()}:${stringMinutes}`;
    },
    newDateFormating(date) {
      const monthNames = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      const dayNames = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];

      let hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12; // the hour '0' should be '12'
      const stringMinutes =
        minutes < 10 ? "0" + minutes.toString() : minutes.toString();
      const stringHours =
        hours < 10 ? "0" + hours.toString() : hours.toString();
      return `${date.getDate()} ${monthNames[date.getMonth()].substring(0,3)} ${date.getFullYear()}, ${stringHours}:${stringMinutes}`

      // return `${date.getDate()} ${
      //   monthNames[date.getMonth()]
      // } ${date.getFullYear()}`;
    },
    dateFormat2(date) {
      const monthNames = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];

      const dayNames = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];

      let hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      const stringMinutes =
        minutes < 10 ? "0" + minutes.toString() : minutes.toString();
      const stringHours =
        hours < 10 ? "0" + hours.toString() : hours.toString();
      // return `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}, ${stringHours}:${stringMinutes} ${ampm}`

      return `${date.getFullYear()}-${
        monthNames[date.getMonth()]
      }-${date.getDate()}`;
    },
  },
});


new Vue({
  router,
  store,
  vuetify,
  vuetoast,
  axios,
  beforeCreate() {
    this.$store.commit("initialEmail");
    this.$store.commit("initialToken");
    this.$store.commit("initialId");
    this.$store.commit("initialIsDataComplete");
  },
  render: (h) => h(App),
}).$mount("#app");