<template>
  <v-app>
    <div v-if="this.$router.history.current.path != '/'">
      <navigation />
      <v-main>
        <toolbar />
        <v-container class="px-4 py-0 fill-height" fluid>
          <v-row class="fill-height">
            <v-col>
              <transition name="fade">
                <router-view></router-view>
              </transition>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </v-app>
</template>

<script>
import Vue from "vue";
import Navigation from "./components/Navigation.vue";
import Toolbar from "./components/AppBar.vue";
import firebase from "firebase/app";
import "firebase/messaging";
import socket from "./socket";
import axios from "axios";

export default Vue.extend({
  name: "App",

  components: {
    Navigation,
    Toolbar,
  },

  updated() {
    if (this.$router.history.current.path != "/") {
      let config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      axios
        .create({ withCredentials: false })
        .get(`${process.env.VUE_APP_API_URL}/v1/user/token/check`, config)
        .then((response) => {
          if (!response.data.error) {
            if (response.data.result.isValidate == true) {
              localStorage.setItem(
                "expires_in",
                response.data.result.expires_in
              );
              if (response.data.result.expires_in - 604800 < Date.now()) {
                this.refreshToken();
              }
            } else {
              this.$store.commit("clearStorage");
              window.location.replace("/");
            }
          } else {
            this.$store.commit("clearStorage");
            window.location.replace("/");
          }
        })
        .catch((error) => {
          // this.isLoading = false;
          // this.$toast.warning(error.response.data.message);
        });
    }
  },

  methods: {
    setInitSession(sessionID) {
      socket.disconnect();
      socket.auth = { sessionID };
      socket.connect();
    },
    refreshToken() {
      let config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getToken}`,
        },
      };

      axios
        .create({ withCredentials: false })
        .get(`${process.env.VUE_APP_API_URL}/v1/user/token/refresh`, config)
        .then((response) => {
          if (response.data) {
            localStorage.setItem("expires_in", response.data.expires_in);
            localStorage.setItem("token", response.data.token);
          } else {
            this.$router.push(`/`);
          }
        })
        .catch((error) => {
          // this.isLoading = false;
          // this.$toast.warning(error.response.data.message);
        });
    },
    receiveMessage() {
      try {
        firebase.messaging().onMessage((payload) => {
          var notification = new Notification(payload.notification.title, {
            icon: payload.notification.image,
            body: payload.notification.body,
          });
          notification.addEventListener("click", function () {
            var payloadData = payload.data["gcm.notification.data"];
            var newPayload = JSON.parse(payloadData);
            if (newPayload.type == "tournament") {
              window.open(
                `https://admin.ngegame.net/tournaments/detail/${newPayload.typeId}`,
                "_self"
              );
            } else if (newPayload.type == "chat") {
              window.open(
                `https://admin.ngegame.net/chat?id=${newPayload.typeId}`,
                "_self"
              );
            }
          });
        });
      } catch (e) {
        console.log(e);
      }
    },
    saveToken(token) {
      let config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getToken}`,
        },
      };

      axios
        .create({ withCredentials: false })
        .post(
          `/notification/add_user_device`,
          {
            deviceId: token,
            deviceType: "web",
          },
          config
        )
        .then((response) => {
          // console.log("user successfully added");
        });
    },
  },

  created() {
    if (this.$store.getters.getToken) {
      if ("Notification" in window) {
        try {
          Notification.requestPermission()
            .then(() => {
              // console.log("Notification permission granted");
              if (
                this.$store.getters.getDeviceId == null ||
                this.$store.getters.getDeviceId == ""
              ) {
                firebase
                  .messaging()
                  .getToken()
                  .then((token) => {
                    // console.log(token);
                    this.saveToken(token);
                    this.$store.commit("setDeviceId", token);
                  });
              }
              this.receiveMessage();
            })
            .catch((err) => {
              console.log("Unable to get token ", err);
            });
        } catch (e) {
          console.log(e);
        }
      }
    }
  },

  mounted() {
    const sessionID = localStorage.getItem("id");

    if (sessionID) {
      //separate
      this.setInitSession(sessionID);

      socket.on("connect_error", (err) => {
        if (err.message === "invalid username") {
          this.usernameAlreadySelected = false;
        }
      });
    }
  },
  destroyed() {
    socket.off("connect_error");
  },

  data: () => ({
    //
  }),
});
</script>
