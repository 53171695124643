import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import auth from "./modules/auth";

export default new Vuex.Store({
  state: {
    email: "",
    token: "",
    id: "",
    isDataComplete: false,
    deviceId: "",
  },
  mutations: {
    setEmail(state, payload) {
      localStorage.setItem('email', payload);
      state.email = payload
    },
    setToken(state, payload) {
      localStorage.setItem('token', payload);
      state.token = payload
    },
    setId(state, payload) {
      localStorage.setItem('id', payload);
      state.id = payload
    },
    setIsDataComplete(state, payload) {
      localStorage.setItem('isDataComplete', payload);
      state.isDataComplete = payload
    },
    setDeviceId(state, payload) {
      if (process.client) {
        localStorage.setItem("deviceId", payload);
      }
      state.deviceId = payload;
    },
    initialEmail(state) {
      state.email = localStorage.getItem('email')
    },
    initialToken(state) {
      state.token = localStorage.getItem('token')
    },
    initialId(state) {
      state.id = localStorage.getItem('id')
    },
    initialIsDataComplete(state) {
      state.isDataComplete = localStorage.getItem('isDataComplete')
    },
    initialDeviceId(state) {
      if (process.client) {
        state.deviceId = localStorage.getItem("deviceId");
      }
    },
    clearStorage(state) {
      window.localStorage.clear();
    }
  },
  actions: {
  },
  modules: {
    auth,
  },
  getters: {
    getEmail: state => state.email,
    getToken: state => state.token,
    getId: state => state.id,
    getIsDataComplete: state => state.isDataComplete,
    getDeviceId: (state) => state.deviceId
  }
})
