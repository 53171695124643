<template>
  <v-navigation-drawer app clipped flat permanent>
    <v-list style="background-color:rgba(85,85,85,1)">
      <v-list-item class="px-2">
        <v-img :src="require('../assets/img/ngegame-text-logo.svg')"></v-img>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list dense nav>
      <div v-for="(item, i) in items" :key="i">
        <v-list-item
          v-if="!item.children"
          :key="item.title"
          link
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-else
          :key="item.title"
          no-action
          :prepend-icon="item.icon"
          :value="false"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>

          <v-list-item
            v-for="item in item.children"
            :key="item.title"
            link
            :to="item.path"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard", path: "/dashboard" },
        { title: "User", icon: "mdi-account", path: "/user" },
        { title: "Tournament", icon: "mdi-tournament", path: "/tournament" },
        { title: "Timeline", icon: "mdi-timeline", path: "/timeline" },
        { title: "Store", icon: "mdi-store", path: "/catalogs" },
        { title: "Chat", icon: "mdi-chat", path: "/chat" },
        {
          title: "Content",
          icon: "mdi-apps",
          children: [
            {
              icon: "mdi-view-headline",
              title: "Meta Data",
              path: "/meta",
            },
            {
              icon: "mdi-help",
              title: "FAQ Data",
              path: "/faq",
            },
            {
              icon: "mdi-gamepad-variant",
              title: "Game List Data",
              path: "/game_list",
            },
          ],
        },
        {
          title: "Report",
          icon: "mdi-alert",
          children: [
            {
              icon: "mdi-account",
              title: "Account",
              path: "/report/account",
            },
            {
              icon: "mdi-tournament",
              title: "Tournament",
              path: "/report/tournament",
            },
            {
              icon: "mdi-message-outline",
              title: "Comment",
              path: "/report/comment",
            },
            {
              icon: "mdi-timeline",
              title: "Timeline",
              path: "/report/timeline",
            },
          ],
        },
      ],
      right: null,
    };
  },
};
</script>