<template>
  <v-toolbar>
    <v-toolbar-title>Ngegame Admin</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn icon @click="onLogout">
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import axios from "axios";

export default {
  methods: {
    onLogout() {
      let config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getToken}`,
        },
      };

      axios
        .create({ withCredentials: false })
        .get(`${process.env.VUE_APP_API_URL}/v1/logout?deviceId=${this.$store.getters.getDeviceId}`, config)
        .then((response) => {
          this.$store.commit("clearStorage");
          window.location.replace("/");
        })
        .catch((error) => {
          this.$store.commit("clearStorage");
          window.location.replace("/");
        });
    },
  },
};
</script>